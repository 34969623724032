<template>
  <win-block>
    <template #win-title-left>Баланс игроков</template>

    <template #win-title-right>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
      ></v-text-field>
    </template>

    <v-card flat min-height="450px">

      <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
      >
        <template v-slot:item.balance="{ item }">

          <v-btn x-small color="primary" outlined elevation="0" @click="formBalance(item)">
            {{ item.balance }}
          </v-btn>

        </template>
      </v-data-table>

    </v-card>

    <v-dialog v-model="form_balance_show" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Пополнить баланс {{ editedItem.user.username }}</v-card-title>
        <v-card-text class="justify-center">
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="6">
                <v-text-field v-model="amount" type="number"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="formBalanceClose">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="formBalanceOK">OK</v-btn>
          <!--          <v-spacer></v-spacer>-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    </win-block>
</template>

<script>
export default {
  components: {
    "win-block": () => import('@/site/componets/winblock'),
  },
  data() {
    return {
      form_balance_show: false,
      amount: null,
      loading: true,
      editedItem: {
        user: {username: ''}
      },
      search: '',
      headers: [
        {
          text: 'Игрок',
          align: 'start',
          sortable: false,
          value: 'user.username',
        },
        {text: 'Бонусы', value: 'balance'},
        {text: 'USID', value: 'user.usid'},
      ],
      items: [],
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.$axios.get(this.$url.shop_user_wallet)
          .then(response => {
            this.items = response.data
            this.loading = false
          })
    },
    formBalance(item) {
      // window.console.log('formBalance',item)
      this.amount = 0
      this.editedItem = item
      this.form_balance_show = true
    },
    formBalanceOK() {
      window.console.log('formBalanceOK', this.editedItem)
      this.$axios.post(this.$url.shop_project_deposit.replace('55555', this.editedItem.user.usid), {"amount": this.amount})
          .then(response => {
            response.data
            this.editedItem.balance = this.editedItem.balance + parseInt(this.amount)
            this.$bus.emit('snackbar', {"text": this.$t("Баланс успешно пополнен")});
          })
          .catch(error => {
            this.$bus.emit('snackbar', {"error": error});
            return Promise.reject(error);
          })
          .finally(() => {
            this.loading = false;
          })
      this.form_balance_show = false
    },
    formBalanceClose() {
      this.form_balance_show = false
    },
  }
}
</script>

<style scoped>

</style>

/*
[
{
"user": {
"username": "ZEX",
"usid": "id10009",
"avatar": "/media/profile_images/avatar9.jpg"
},
"balance": 0
},
{
"user": {
"username": "superuser",
"usid": "id10001",
"avatar": null
},
"balance": 332
}
]
*/